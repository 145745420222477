import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { reduxForm, Field, FieldArray, formValueSelector, change } from 'redux-form';
import { Grid } from './../../../assets/theme/layout';
import { Page, Wrapper, Paper, Content, Loading, Button, Toolbar, Modal, ButtonsGroup, Upload, UploadImagesFieldArray, ScheduleFieldArray, FieldsCustomArray } from './../../../components/ui';
import { InputTextField, SelectField, OptionField } from './../../../components/ui/input';
import { CountrySelect, StateSelect, FieldCustomList, FieldCustomForm, FranchiseSelect } from './../../../scenes';
import { StoreService } from './../../../services';
import { isEmail, findPermissionProps, hasPermission } from './../../../utilities/utils';

import API from './../../../config/api';
import TEXT from './../../../config/text';

const validate = values => {

  const errors = {};

  if (!values.name) {
    errors.name = TEXT.validation.required;
  }else if(values.name.length > 1024){
    errors.name = TEXT.validation.maxlength(1024);
  }

  if (!values.position) {
    errors.position = TEXT.validation.required;
  }else if(values.position <= 0){
    errors.position = TEXT.validation.minlength(1);
  }

  if (!values.email) {
    errors.email = TEXT.validation.required;
  } else if (isEmail(values.email)) {
    errors.email = TEXT.validation.email_invalid;
  } else if (values.email.length > 255){
    errors.email = TEXT.validation.maxlength(255);
  }

  if (values.office_phone) {
    if (values.office_phone.length > 255) {
      errors.office_phone = TEXT.validation.maxlength(255);
    }
  }

  if (values.sales_phone) {
    if (values.sales_phone.length > 255) {
      errors.sales_phone = TEXT.validation.maxlength(255);
    }
  }

  if (values.whatsapp_phone) {
    if (values.whatsapp_phone.length > 255) {
      errors.whatsapp_phone = TEXT.validation.maxlength(255);
    }
  }

  if (values.website) {
    if (values.website.length > 255) {
      errors.website = TEXT.validation.maxlength(255);
    }
  }

  // @Address
  if (!values.address_street) {
    errors.address_street = TEXT.validation.required;
  } else if (values.address_street.length > 255) {
    errors.address_street = TEXT.validation.maxlength(255);
  }

  if (!values.address_number) {
    errors.address_number = TEXT.validation.required;
  } else if (values.address_number.length > 255) {
    errors.address_number = TEXT.validation.maxlength(255);
  }

  if (values.address_floor) {
    if (values.address_floor.length > 255) {
      errors.address_floor = TEXT.validation.maxlength(255);
    }
  }

  if (values.address_apartment) {
    if (values.address_apartment.length > 255) {
      errors.address_apartment = TEXT.validation.maxlength(255);
    }
  }

  if (!values.zip) {
    errors.zip = TEXT.validation.required;
  } else if (values.zip.length > 10) {
    errors.zip = TEXT.validation.maxlength(10);
  }

  if (values.country) {
    if (values.country === '-1') {
      errors.country = TEXT.validation.required;
    }
  }

  // if (values.state) {
  //   if (values.state === '-1') {
  //     errors.state = TEXT.validation.required;
  //   }
  // }

  if (values.city) {
    if (values.city.length > 255) {
      errors.city = TEXT.validation.maxlength(255);
    }
  }

  if (!values.lat) {
    errors.lat = TEXT.validation.required;
  }

  if (!values.lng) {
    errors.lng = TEXT.validation.required;
  }

  if(!values.franchise){
    errors.franchise = TEXT.validation.required;
  }

  return errors;

}

class Page__StoreForm extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        permission: null,
        name: "...",
        title: '...',
        loading: true,
        submited: false,
        id: null,
        //type: 'category',
        entity: null,
        categories: [],
        images: [],
        country: null,
        countries: [],
        states: [],
        franchise: null,
        fieldFormShow: false
      };
    };

    /**
    * @Event
    * @Description: Is invoked immediately after a component is mounted.
    */
    componentDidMount(){
      this._Init();
    };

    /*
    ** @Event
    ** @Description: Init
    */

    _Init = () => {

      this.setState({ permission: findPermissionProps(this.props.session, API.permissions.store, this.props.t('text.stores')) });

      const id = this.props.match.params.id;

      if(id){
        this._Find(id);
      }else{
        this.setState({ loading: false, title: this.props.t('text.new_entry') });
      }

      this.props.dispatch(change(this.props.form, 'schedule', API.store.schedule ));

    }

    /*
    ** @Service
    ** @Description: Save Store
    */

    _Save = (values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true  });

      // @Service
      StoreService.save(values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.store_created')
        });

        // @Redirect
        this.props.history.push('/stores');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Update Store
    */

    _Update = (id, values) => {

      // @State
      // @Description: Loading show
      this.setState({ loading: true, submited: true });

      // @Service
      StoreService.update(id, values).then((response) => {

        // @Dispatch alert success
        this.props.alertShow({
          open: true,
          severity: 'success',
          message: this.props.t('messages.store_updated')
        });

        // @Redirect
        this.props.history.push('/stores');

      }).catch((error) => {

        // @State
        // @Description: Loading hide
        this.setState({ loading: false, submited: false });

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data : TEXT.message.error
        });

      });

    };

    /*
    ** @Service
    ** @Description: Find Store
    */

    _Find = (id) => {

      // @Service
      StoreService.find(id).then((response) => {
        console.log('RESPONSE: ', response)

        const form = this.props.form;

        // @State
        // @Description: Set note update into state
        this.setState({
          loading: false,
          id: response.store._id,
          entity: response.store,
          title: response.store.name
        });

        // @Form
        // @Description: Set form fields

        // @General
        this.props.dispatch(change(form, 'name', this.state.entity.name ));
        this.props.dispatch(change(form, 'enabled', this.state.entity.enabled ));

        // @Schedule
        if(this.state.entity.schedule.length > 0){
          this.props.dispatch(change(form, 'schedule', this.state.entity.schedule ));
        }

        // @Contact
        this.props.dispatch(change(form, 'email', this.state.entity.email ));
        this.props.dispatch(change(form, 'sales_phone', this.state.entity.sales_phone ));
        this.props.dispatch(change(form, 'office_phone', this.state.entity.office_phone ));
        this.props.dispatch(change(form, 'whatsapp_phone', this.state.entity.whatsapp_phone ));
        this.props.dispatch(change(form, 'website', this.state.entity.website ));

         // @Franchise
         if(this.state.entity.country){
          this.props.dispatch(change(form, 'franchise', this.state.entity.franchise._id ));
          this.setState({ franchise: this.state.entity.franchise._id });
        }

        // @Address
        this.props.dispatch(change(form, 'address_street', this.state.entity.address_street ));
        this.props.dispatch(change(form, 'address_number', this.state.entity.address_number ));
        this.props.dispatch(change(form, 'address_floor', this.state.entity.address_floor ));
        this.props.dispatch(change(form, 'address_apartment', this.state.entity.address_apartment ));
        this.props.dispatch(change(form, 'zip', this.state.entity.zip ));
        this.props.dispatch(change(form, 'city', this.state.entity.city ));
        // this.props.dispatch(change(form, 'country', this.state.entity.country ));
        // this.props.dispatch(change(form, 'state', this.state.entity.state ));

        // @Country
        if(this.state.entity.country){
          this.props.dispatch(change(form, 'country', this.state.entity.country._id ));
          this.setState({ country: this.state.entity.country._id });
        }

        // @State
        if(this.state.entity.state){
          this.props.dispatch(change(form, 'state', this.state.entity.state._id ));
          this.setState({ country: this.state.entity.country._id });
        }

        this.props.dispatch(change(form, 'map', this.state.entity.map ));
        this.props.dispatch(change(form, 'lat', this.state.entity.lat ));
        this.props.dispatch(change(form, 'lng', this.state.entity.lng ));

        // @Images
        this.props.dispatch(change(form, 'images', this.state.entity.images ));

        // @Fields
        this.props.dispatch(change(form, 'fields', this.state.entity.fields ));

      }).catch((error) => {

        // @Dispatch alert error
        this.props.alertShow({
          open: true,
          severity: 'error',
          message: error.data ? error.data.message : TEXT.message.error
        });

      });

    };

    /*
    ** @Event
    ** @Description: OnUpload file
    */

    onUploadFile = (files) => {
      this.props.dispatch(change(this.props.form, 'images', files ));
    };

    /*
    ** @Submit
    ** @Description: Submit form
    */
    handleSubmitForm = (values) => {
      if (this.state.id) {
        this._Update(this.state.id, values);
      } else {
        this._Save(values);
      }
    };

    // @Event
    // @Description: On change country
    handleOnChangeCountry = (event) => {

      // @Store
      // @Description: Set country id
      this.setState({ country: event.target.value });

      // @Form
      // @Description: Set state id
      this.props.dispatch(change(this.props.form, 'state', '' ));

    };

    // @Event
    // @Description: Load default country
    handleOnLoadCountry = (country) => {

      if(country){
        // @Store
        // @Description: Set country id
        this.setState({ country: country._id });

        // @Form
        // @Description: Set country id
        this.props.dispatch(change(this.props.form, 'country', country._id ));
      }

    };

    //   // @Event
    // // @Description: Load default Franchise
    // handleOnLoadFranchise = (franchise) => {

    //   if(franchise){
    //     // @Store
    //     // @Description: Set country id
    //     this.setState({ franchise: franchise._id });

    //     // @Form
    //     // @Description: Set country id
    //     this.props.dispatch(change(this.props.form, 'franchise', franchise._id ));
    //   }

    // };

    /*
    ** @Event
    ** @Description: Show Form Fields
    */

    handleField = () => {
      this.setState({ fieldFormShow: true });
    };

    /*
    ** @Event
    ** @Description: Close Form Fields
    */

    handleFieldClose = () => {
      this.setState({ fieldFormShow: false });
    };

    /*
    ** @Event
    ** @Description: OnChange Custom Field
    */

    handleOnSubmitCustomField = (fields) => {
      this.props.dispatch(change(this.props.form, 'fields', fields ));
      this.handleFieldClose();
    };

    // @Render
    render(){

      const { handleSubmit, invalid, images, fields, t } = this.props;
      const { permission, name, loading, title, submited, country, fieldFormShow } = this.state;

      return(
        <Page>

          <Wrapper>

            <Toolbar
              title={permission ? permission.name : name}
              dividerMobile
            >
              <Button
                component={ NavLink }
                to="/stores"
                icon="back"
                size="medium"
              >
                {t('button.back')}
              </Button>
            </Toolbar>

            <Paper disablePadding>

              <Toolbar
                title={ title }
                size="medium"
                variant="divider"
                disableMargin
              />

              <Modal
                title={this.props.t('text.custom_fields')}
                status={fieldFormShow}
                handleCancel={() => this.handleFieldClose()}
              >
                <FieldCustomForm
                  component={API.permissions.store}
                  currentValues={fields}
                  onSubmit={this.handleOnSubmitCustomField}
                />
              </Modal>

              {/* Form Category */}
              <form noValidate autoComplete="off" onSubmit={ handleSubmit(this.handleSubmitForm) }>

                <Content divider disableMargin>

                  { (submited || loading) && (
                    <Loading variant="absolute"/>
                  )}

                  {/* General */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.general')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Field
                          name="name"
                          component={ InputTextField }
                          label={t('field.name')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                        <Field
                          name="enabled"
                          component={ SelectField }
                          label={t('field.status')}
                          required
                        >
                          <OptionField value="true">{t('field.option_field.active')}</OptionField>
                          <OptionField value="false">{t('field.option_field.disabled')}</OptionField>
                        </Field>
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={6} xs={6}>
                        <Field
                          name="position"
                          component={ InputTextField }
                          label={t('field.position')}
                          type="number"
                          required
                          min={1}
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Contact information */}
                  <Content disablePadding divider>
                    <Toolbar
                      title={t('text.contact')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="email"
                          component={ InputTextField }
                          label={t('field.email')}
                          type="email"
                          required
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="sales_phone"
                          component={ InputTextField }
                          label={t('field.phone_sales')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="office_phone"
                          component={ InputTextField }
                          label={t('field.phone_office')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="whatsapp_phone"
                          component={ InputTextField }
                          label={t('field.phone_whatsapp')}
                          placeholder="Ej. +54911576534"
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                        <Field
                          name="website"
                          component={ InputTextField }
                          label={t('field.website')}
                          placeholder="Ej. https://www.example.com"
                          type="text"
                        />
                      </Grid>
                      { hasPermission(this.props.session.user.permissions, API.permissions.franchise) && (
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Field
                          name="franchise"
                          component={ FranchiseSelect }
                          onLoad={ this.handleOnLoadFranchise }
                          label='Franquicia'
                        />
                      </Grid>
                      )}
                    </Grid>
                  </Content>

                  {/* Location */}
                  <Content disablePadding divider>
                    <Toolbar
                      title={t('text.location')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <Field
                          name="address_street"
                          component={ InputTextField }
                          label={t('field.street')}
                          type="text"
                          autoComplete="no"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={5} md={12} sm={12} xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                            <Field
                              name="address_number"
                              component={ InputTextField }
                              label={t('field.number')}
                              type="text"
                              autoComplete="no"
                              required
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                            <Field
                              name="address_floor"
                              component={ InputTextField }
                              label={t('field.floor')}
                              type="text"
                              autoComplete="no"
                            />
                          </Grid>
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                            <Field
                              name="address_apartment"
                              component={ InputTextField }
                              label={t('field.apartment')}
                              type="text"
                              autoComplete="no"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xl={3} lg={3} md={4} sm={4} xs={12}>
                        <Field
                          name="zip"
                          component={ InputTextField }
                          label={t('field.postal_code')}
                          type="text"
                          autoComplete="no"
                          placeholder="Ej. C1123 o 1123"
                          required
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Field
                          name="country"
                          component={ CountrySelect }
                          label={t('field.country')}
                          required
                          onLoad={ this.handleOnLoadCountry }
                          onChange={ this.handleOnChangeCountry }
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Field
                          name="state"
                          component={ StateSelect }
                          label={t('field.state')}
                          country={ country }
                        />
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                        <Field
                          name="city"
                          component={ InputTextField }
                          label={t('field.city')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Field
                          name="map"
                          component={ InputTextField }
                          label={t('field.embebed_map')}
                          type="text"
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Field
                          name="lat"
                          component={ InputTextField }
                          label={t('field.latitude')}
                          type="text"
                          required
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                        <Field
                          name="lng"
                          component={ InputTextField }
                          label={t('field.longitude')}
                          type="text"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Schedule */}
                  <Content disablePadding divider>
                    <Toolbar
                      title={t('text.schedules')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FieldArray
                          name="schedule"
                          component={ ScheduleFieldArray }
                        />
                      </Grid>
                    </Grid>
                  </Content>

                  {/* Images */}
                  <Content marginBottom>
                    <Toolbar
                      title={t('text.images')}
                      size="small"
                    />
                    <Grid container spacing={3}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

                        <Upload
                          attachments={images ? images : []}
                          multiple={true}
                          avatar={true}
                          paper={true}
                          onSuccess={this.onUploadFile.bind(this)}
                        />

                        {/* Array of Images */}
                        <FieldArray
                          name="images"
                          component={ UploadImagesFieldArray }
                        />

                      </Grid>
                    </Grid>
                  </Content>

                  {/* Fields custom */}
                  <Content disablePadding>
                    <Grid container spacing={0}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <FieldCustomList
                          component={API.permissions.store}
                          currentValues={fields || []}
                          onOpen={this.handleField}
                        />
                        <FieldArray
                          name="fields"
                          component={FieldsCustomArray}
                        />
                      </Grid>
                    </Grid>
                  </Content>

                </Content>

                {/* Footer */}
                <Content>
                  <ButtonsGroup align="right">
                    <Button
                      component={ NavLink }
                      to="/stores"
                      color="danger"
                      size="medium"
                      icon="cancel"
                    >
                      {t('button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      size="medium"
                      icon="add"
                      color="success"
                      disabled={ invalid || submited }
                    >
                      {t('button.save')}
                    </Button>
                  </ButtonsGroup>
                </Content>

              </form>

            </Paper>

          </Wrapper>

        </Page>
      )
    }

};

Page__StoreForm = reduxForm({
  form: 'Page__StoreForm',
  validate,
  initialValues: {
    enabled: true,
    position: 1,
    images: [],
    schedule: []
  }
})(Page__StoreForm);

const selector = formValueSelector('Page__StoreForm');

Page__StoreForm = connect(state => {
  const images = selector(state, 'images');
  const fields = selector(state, 'fields') || [];
  return { images, fields }
})(Page__StoreForm)

const mapStateToProps = (state) => {
  return {
    session: state.userState
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    alertShow: (data) => {
      dispatch({
        type: 'ALERT_SHOW',
        data: data
      });
    }
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Page__StoreForm));
